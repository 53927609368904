import React from 'react'
import { Button } from '../ButtonElement'
import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ButtonWrap, ImgWrap, Img } from './AboutElements'
import { useTranslation } from 'react-i18next'



const AboutTerms = () => {
    const {t} = useTranslation();
    return (
        <>
          <AboutContainer lightBg={false} id = 'general-terms'>
              <AboutWrapper>
                  <AboutRow imgStart = {true}>
                      <Column1>
                      <TextWrapper>
                          <TopLine>{''}</TopLine>
                            <Heading lightText={true}>{t("Terms")}</Heading> 
                            <Subtitle darkText={false}>{t("generalTerms")}</Subtitle>
                           
                      </TextWrapper>
                      </Column1>
                      <Column2>
                      <ImgWrap>
                      <Img src={require('../img/terms.svg').default} alt = {'Car'}/>
                      </ImgWrap>
                      </Column2>
                  </AboutRow>
              </AboutWrapper>
              </AboutContainer>  
        </>
    )
}
export default AboutTerms
