import React from 'react'
import ImageGallery from '../ImageGallery'

const References = () => {
    return (
        <>
          <ImageGallery/>  
        </>
    )
}

export default References
