import React from 'react'
import { LanguageButton } from '../nav/navbar-elems'
import { SidebarContainer, Icon, ClosedIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideButtonWrap, SidebarRoute, SidebarLangLink } from './sidebar-elems'
import { useTranslation } from "react-i18next";
import 'flag-icon-css/css/flag-icon.min.css'

const Sidebar = ({isOpen, toggle}) => {
    const {t, i18n} = useTranslation();
    function handleClick(lang){
        i18n.changeLanguage(lang)
    }
    
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick = {toggle}>
                <ClosedIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to = "about" onClick={toggle}>
                    {t('About')}
                    </SidebarLink>
                    <SidebarLink to = "contacts" onClick={toggle}>
                    {t('Contacts')}
                    </SidebarLink>
                    <SidebarLink to = "services" onClick={toggle}>
                    {t('Services')}
                    </SidebarLink>
                    <SidebarLink to = "general-terms" onClick={toggle}>
                    {t('Terms')}
                    </SidebarLink>
                    <SidebarLink to = "process" onClick={toggle}>
                    {t('Process')}
                    </SidebarLink>
                </SidebarMenu>
                <SideButtonWrap>
                    <SidebarRoute to="/portfolio">{t('Portfolio')}</SidebarRoute>
                </SideButtonWrap>
                <SidebarLangLink onClick ={(e)=>{e.stopPropagation(); handleClick('es')}} >
                           <span className = "flag-icon flag-icon-es"></span>
                        </SidebarLangLink>
                        <SidebarLangLink onClick ={(e)=>{e.stopPropagation(); handleClick('en')}}>
                        <span className = "flag-icon flag-icon-gb" ></span>
                        </SidebarLangLink>
                </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
