import React from 'react'
import { Button } from '../ButtonElement'
import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ButtonWrap, ImgWrap, Img } from './AboutElements'
import { useTranslation } from 'react-i18next'



const AboutSectionME = () => {
    const {t} = useTranslation();
    return (
        <>
          <AboutContainer lightBg={false} id = 'about'>
              <AboutWrapper>
                  <AboutRow imgStart = {false}>
                      <Column1>
                      <TextWrapper>
                          <TopLine>{''}</TopLine>
                            <Heading lightText={true}>{'Vera Atanasova'}</Heading> 
                            <Subtitle darkText={false}>{t('AboutMe')}</Subtitle>
                           
                      </TextWrapper>
                      </Column1>
                      <Column2>
                      <ImgWrap>
                      <Img src={require('../img/vera/vera.jpg').default} alt = {'Car'}/>
                      </ImgWrap>
                      </Column2>
                  </AboutRow>
              </AboutWrapper>
              </AboutContainer>  
        </>
    )
}

export default AboutSectionME
