import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 1.2rem;
    position:sticky;
    top:0;
    z-index:10;

@media screen and (max-width:960px){
    transition: 0.8s all ease;
}

`;

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index:1;
width: 100%;
padding: 0 24px;
max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
color: #fff;
justify-self:flex-start;
cursor: pointer;
font-size: 1.5rem;
display:flex;
align-items: center;
margin-left:24px;
font-weight:bold;
text-decoration:none;
`;

export const MobileIcon = styled.div`
display:none;

@media screen and (max-width:768px){
    display:block;
    position:absolute;
    top:0;
    right:0;
    transform:translate(-100%, 60%);
    font-size:1.8rem;
    cursor:pointer;
    color: #fff;
}
`;

export const NavMenu = styled.ul`
display:flex;
align-items:center;
list-style:none;
text-align:center;
margin: 0 auto;


@media screen and (max-width:768px){
    display:none;
}
`;

export const NavItem = styled.li`
height:80px;
`;

export const NavLinks = styled(LinkS)`
justify-content: center;
color: #fff;
display:flex;
align-items:center;
text-decoration:none;
padding: 0 1rem;
height:100%;
cursor:pointer;

&.active{
    border-bottom: 3px solid #01bf71;
}
`;

export const NavButton = styled.nav`
display: flex;
align-items: center;

@media screen and (max-width:768px){
    display:none;
}
`;

export const NavButtonLink = styled(LinkR)`
justify-content: center;
color: #fff;
display:flex;
align-items:center;
text-decoration:none;
padding: 0 1rem;
height:100%;
cursor:pointer;

&.active{
    border-bottom: 3px solid #01bf71;
}
`;

export const LanguageButton = styled.button`
background-color: transparent;
border-color: transparent;
font-size: 30px;
margin-left: 15px;
@media screen and (max-width:960px){
   display: none;
}
&:hover{
    cursor: pointer;
}
`