import { SocialIcons, SocialIconLink} from './contacts'
import { FaWhatsapp, FaFacebook } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'


export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Vera Atanasova',
    description: `Born on 13.10.1968 in Plovdiv, Bulgaria. Finishes middle school in the neighbourhood in which she lives with her parents and her brother.   
    In 1982 begins her higher education in a specialized school for fine arts "TsankoLavrenov" in Plovdiv and later on graduates in 1987. After that is accepted in university "St.St. Cyril and Methodius" (VelikoTarnovo) where she graduates in 1992 with Master's degree in "Pedagogy of fine arts and aesthetics".
    In 1995 enrolls for a Bachelor's degree at "D. A. Tsankov" university in Svishtov and graduates in the field of "Finance and insurance" in 2000
    During the time in the faculty of fine arts participates in a lot of student exhibitions, including an international one in London in 1991.
    From 2001 until 2010, through a Swedish mediator, makes oil paintings on request which theme is the Swedish nature and culture. Participates in numerous exhibits and has many paintings sold in Sweden.
    Simultaneously, since 1992 until present day specializes in the field of portrait art. Works mainly on the street and makes pastel paintings both from life and from photos. After 2000 draws mainly from photos. Her portraits are in the homes of hundreds of families from all European countries.
        
    Currently works and lives in Los Cristianos, Tenerife. `,
    buttonLabel: 'Portfolio',
    imgStart: false,
    img: require('../img/vera/vera.jpg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjTwo = {
    id: 'contacts',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'contacts',
    headline: "",
    description: <SocialIcons>
    <SocialIconLink href="//facebook.com/vera.atanasova3" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
    <SocialIconLink href="//wa.me/34633340957" target="_blank" aria-label="Whatsapp"><FaWhatsapp /></SocialIconLink>
    <SocialIconLink a href="mailto:veraat13@gmail.com" target="_blank" aria-label="Email" ><MdEmail /></SocialIconLink>
    </SocialIcons>,
    buttonLabel: 'Portfolio',
    imgStart: true,
    img: require('../img/contact-us.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjThree = {
    id: 'general-terms',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Terms',
    description: `The above prices and sizes are rough and can be altered after further specifying the details of the execution. 
    Prices and compositions for the group portraits are formed based on clients' demands and complexity of the task. Prices and sizes for the oil portraits are subject to further communication.
    The cost for regular shipment via standard postal services is €12. The delivery process usually takes 10 days. The cost for express deliveries varies depending on the courier chosen.`,
    buttonLabel: 'something',
    imgStart: true,
    img: require('../img/terms.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjFour = {
    id: 'process',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Process',
    description: ` 1. Selection of a photograph: The preferred photograph should be with good quality and  meet the requirements for clarity and lighting.

    2. Order confirmation: After the selection is complete, the final price and deadline are arranged. The client deposits 20% of the confirmed total amount via bank transfer. After the portrait is complete and received, the client transfers the remainder of the sum plus the delivery costs.

    3. Terms: In case the client is not satisfied with the quality of the execution, they have a right to request the deposit back, and would only have to cover the delivery charges. `,
    buttonLabel: 'something',
    imgStart: false,
    img: require('../img/process.svg').default,
    alt: 'Car',
    dark: true,
    primary: false,
    darkText: true,
}