import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon,ServicesH2, ServicesP, ServicesP1  } from './ServicesElements'
import Icon1 from '../img/single-portrait.svg'
import Icon2 from '../img/double-portrait.svg'
import Icon3 from '../img/triple-portrait.svg'
import Icon4 from '../img/quadruple-portrait.svg'
import { useTranslation } from "react-i18next";


const Services = () => {
    const { t } = useTranslation();
    return (
        <>
          <ServicesContainer id = "services">
              <ServicesH1>{t("servicesMain")}
                  <ServicesP1>{t("servicesDetails")}</ServicesP1>
                </ServicesH1>
              
              <ServicesWrapper>
                  
                      <ServicesCard>
                          <ServicesIcon src={Icon1}/>
                          <ServicesH2>{t("singlePortrait")}</ServicesH2>
                          <ServicesP>{t("singlePeople")} ({t("approximateSize")} 35x45cm) </ServicesP>
                          <ServicesP><br /></ServicesP>
                          <ServicesP>{t("price")}: €80</ServicesP>
                      </ServicesCard>
                      <ServicesCard>
                          <ServicesIcon src={Icon2}/>
                          <ServicesH2>{t("doublePortrait")}</ServicesH2>
                          <ServicesP>{t("doublePeople")} ({t("approximateSize")} 40x50cm)</ServicesP>
                          <ServicesP><br /></ServicesP>
                          <ServicesP>{t("price")}: €140</ServicesP>
                      </ServicesCard>
                      <ServicesCard>
                          <ServicesIcon src={Icon3}/>
                          <ServicesH2>{t("triplePortrait")}</ServicesH2>
                          <ServicesP>{t("triplePeople")} ({t("approximateSize")} 46x55cm)</ServicesP>
                          <ServicesP><br /></ServicesP>
                          <ServicesP>{t("price")}: €200</ServicesP>
                      </ServicesCard>
                      <ServicesCard>
                          <ServicesIcon src={Icon4}/>
                          <ServicesH2>{t("quadruplePortrait")}</ServicesH2>
                          <ServicesP>{t("quadPeople")} ({t("approximateSize")} 50x65cm)</ServicesP>
                          <ServicesP><br /></ServicesP>
                          <ServicesP>{t("price")}: €250</ServicesP>
                      </ServicesCard>
                    </ServicesWrapper>
              </ServicesContainer>  
        </>
    )
}

export default Services
