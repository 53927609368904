import React, { useState, useEffect } from 'react'
import './imagefilter.css'
import { SRLWrapper } from 'simple-react-lightbox'
import { Icon } from '../ImageGallery/GalleryElements'
import { images } from './imagedata'
import {FaHome} from 'react-icons/fa'
import {HiChevronLeft} from 'react-icons/hi'
import { animateScroll as scroll } from 'react-scroll'
import { Button } from '../ButtonElement'
import { HeroBtnWrapper } from '../HeroSection/HeroElements'
import {RiArrowUpSLine, RiArrowUpLine} from 'react-icons/ri'


const ImageFilter = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    const [hover,setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    };
    
    const [tag, setTag] = useState("all");
    const [filteredImages, setFilteredImages] = useState([]);
    useEffect(() => {
       tag === 'all' ? setFilteredImages(images) : setFilteredImages(images.filter(image => image.tag === tag))
    }, [tag]);

    console.log(tag, filteredImages, images)
    
    return (
        <>
            <div className = "main">
                <div className = "tags">
                <Icon to = "/"><HiChevronLeft/><FaHome/></Icon>
                <TagButton name="all" handleSetTag={setTag} tagActive={tag === 'all' ? true : false} />
                <TagButton name="colour portraits" handleSetTag={setTag} tagActive={tag === 'colour portraits' ? true : false}/>
                <TagButton name= "B&W" handleSetTag={setTag} tagActive={tag === 'B&W' ? true : false}/>
                <TagButton name="compositions" handleSetTag={setTag} tagActive={tag === 'compositions' ? true : false}/>
                <TagButton name="process" handleSetTag={setTag} tagActive={tag === 'process' ? true : false}/>
                <TagButton name="oil orders" handleSetTag={setTag} tagActive={tag === 'oil orders' ? true : false}/>
                <TagButton name="animals" handleSetTag={setTag} tagActive={tag === 'animals' ? true : false}/>
                <TagButton name="other" handleSetTag={setTag} tagActive={tag === 'other' ? true : false}/>
                <TagButton name="archives" handleSetTag={setTag} tagActive={tag === 'archives' ? true : false}/>
                <TagButton name="references" handleSetTag={setTag} tagActive={tag === 'references' ? true : false}/>
                </div>
                <SRLWrapper>
                <div className = "container">
                    { filteredImages.map(image => <div key={image.id} className = "image-card"><a href ={`/images/${image.imgName}`}><img className = "image" src={`/images/${image.imgName}`} alt={image.alt}/></a></div>)}
                </div>
                </SRLWrapper>
                <HeroBtnWrapper>
                <Button onMouseEnter={onHover} onMouseLeave={onHover} onClick={toggleHome}>BACK TO TOP{hover ? <RiArrowUpLine/> : <RiArrowUpSLine/>}</Button>
                </HeroBtnWrapper>
            </div>
        </>
    )
}

const TagButton = ( {name, handleSetTag, tagActive} ) => {
    return <button className={`tag ${tagActive ? 'active' : null}`} onClick = { () => handleSetTag(name)}>{name.toUpperCase()}</button>
};

export default ImageFilter
