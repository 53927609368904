import React from 'react'
import ImageFilter from '../ImageFilter'
import {Helmet} from 'react-helmet'
import { useTranslation } from "react-i18next";




const PortfolioPage = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet>
            <title>{t('Portfolio')}</title> 
            <meta name="description" content="Vera Atanasova portfolio"/>
            </Helmet>
            <ImageFilter/>
        </div>
    )
}

export default PortfolioPage
