import styled from "styled-components"


export const SocialIcons = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 300px;
`

export const SocialIconLink = styled.a`
color: #fff;
font-size: 60px;
`

