import React from 'react';
import './App.css';
import SimpleReactLightbox from 'simple-react-lightbox'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Home from './comps/pages';
import Portfolio from './comps/pages/portfolio';
import References from './comps/pages/references';
import Navbar from './comps/nav';

function App() {
  return (
    <Router>
      <Route exact path='/' component={Navbar} />
      <Switch>
        <Route path="/" component={ Home } exact/>
        <SimpleReactLightbox>
        <Route path="/portfolio" component={ Portfolio } exact/>
        </SimpleReactLightbox>
        <Route path="/references" component={ References } exact/>
      </Switch>
      
    </Router>
  );
}

export default App;
