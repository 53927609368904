import styled from "styled-components";
import {MdClose} from 'react-icons/md'
import {Link} from 'react-router-dom';

export const Gallery = styled.div`
    margin-top: 50px;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width:33%;
    column-width: 33%;
    padding: 0 12px;

    @media screen and (max-width: 991px){
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    @media screen and (max-width: 480px){
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width:100%;
        column-width: 100%;
    }
`

export const Container = styled.div`
min-height: 692px;
position: fixed;
bottom: 0;
left: 0;
right: 0;
top:0;
z-index: 0;
overflow: hidden;
background: linear-gradient(108deg, rgba(1, 147,86,1)0%, rgba(10, 201,122,1)100%);
`;



export const Icon = styled(Link)`
margin-bottom: 20px;
display: inline-block;
text-decoration: none;
color: #fff;
font-weight: 700;
font-size: 32px;
width: 100%;
text-align:center;
border-bottom: 1px solid #fff;

@media screen and (max-width: 768px){
    letter-spacing:20px;
 }
`;

export const GalleryImages = styled.div`
    width: 100%;
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    &:hover{
        filter: opacity(.8);
    }
`

export const modelOpen = styled.div`
    visibility: visible;
    opacity: 1;
    transform: scale(1);
`

export const modelClosed = styled.div`
    
width: 100%;
height: 100vh;
position: fixed;
top:0;
left:0;
display: flex;
justify-content: center;
align-items: center;
background-color: #000;
transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
visibility: hidden;
opacity: 0;
transform: scale(0);
overflow: hidden;
z-index: 999;
`

export const OpenModelImage = styled.img`
    width: auto;
     max-width: 100%;
     height: auto;
     max-height: 100%;
     display: block;
     line-height: 0;
     box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
`

export const CloseImage = styled(MdClose)`
    position: fixed;
    top:10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    cursor: pointer;
`

