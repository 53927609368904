import React from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
import { FaFacebook, FaWhatsapp } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { animateScroll as scroll } from 'react-scroll'
import { useTranslation } from "react-i18next";


const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    
    const { t } = useTranslation();

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>{t('websiteContent')}</FooterLinkTitle>
                                <FooterLink to = "about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('About')}</FooterLink>
                                <FooterLink to = "services" smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Services')}</FooterLink>
                                <FooterLink to = "general-terms" smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Terms')}</FooterLink>
                                <FooterLink to = "process" smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Process')}</FooterLink>
                                <FooterLink to = "contacts" smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t('Contacts')}</FooterLink>
                            
                        </FooterLinkItems>
                        </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to ='/' onClick={toggleHome}>
                        {t('returnTop')}
                        </SocialLogo>
                        <WebsiteRights>Copyright © {new Date().getFullYear()} Vera Atanasova Atanasova. {t('rightsReserved')}</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//facebook.com/vera.atanasova3" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
                            <SocialIconLink href="//wa.me/34633340957" target="_blank" aria-label="Whatsapp"><FaWhatsapp /></SocialIconLink>
                            <SocialIconLink a href="mailto:veraat13@gmail.com" target="_blank" aria-label="Email"><MdEmail /></SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
