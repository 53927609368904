import React, {useState} from 'react'
import './Gallerystyles.css'
import { Gallery, GalleryImages, CloseImage, Container, Icon, OpenModelImage } from './GalleryElements'

import Img1 from '../img/Archive/img1.jpg'
import Img2 from '../img/Archive/img2.jpg'
import Img3 from '../img/Archive/img3.jpg'


const ImageGallery = () => {

    let data = [
        {
            id:1,
            imgSrc:Img1,
        },
        {
            id:2,
            imgSrc:Img2,
        },
        {
            id:3,
            imgSrc:Img3,
        },
    ];
    
    const [model, setModel] = useState(false);
    
    const [tempImgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }
    
    return (
        <>
            <Container>
              <Icon to = "/">Home</Icon>

             
            <div className={model ? "model open" : "model"}>
                <OpenModelImage src = {tempImgSrc} alt = 'placeholder' />
                    <CloseImage onClick={()=>setModel(false)}/>
                    
            </div>
            
            <Gallery>
                {data.map((item,index) => {
                    return(
                        
                        <GalleryImages key={index} onClick={()=>getImg(item.imgSrc)}>
                            <img src={item.imgSrc} style = {{width: '100%'}} alt = 'placeholder'/>
                        </GalleryImages>
                        
                    )
                })}

            </Gallery>
            </Container>
        </>
    )
}

export default ImageGallery
