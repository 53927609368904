import React, {useState} from 'react'
import HeroSection from '../HeroSection';
import Navbar from '../nav'
import Sidebar from '../sidebar'
import AboutSection from '../AboutSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../AboutSection/Data';
import Services from '../Services';
import Footer from '../Footer'
import JotformEmbed from 'react-jotform-embed';
import {Helmet} from 'react-helmet'
import AboutSectionME from '../AboutSection/aboutme';
import AboutTerms from '../AboutSection/aboutTerms';
import AboutProcess from '../AboutSection/aboutProcess';
import { useTranslation } from "react-i18next";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
               <title>{t('homeTitle')}</title> 
               <meta name="description" content="Vera Atanasova - portrait artist with more than 30 years of experience, residing in Tenerife. Obtain a free quote by submitting the form."/>
               <meta name="keywords" content="free quote, portrait artist, experienced, Tenerife, Los Cristianos, cheap portraits, portraits, cheap"/>
            </Helmet>
           <Sidebar isOpen={isOpen} toggle={toggle}/>
           <Navbar toggle={toggle}/> 
           <HeroSection/>
           <AboutSectionME/>
           <Services/>
           <AboutTerms/>
           <AboutProcess/>
           <AboutSection {...homeObjTwo}/>
           <JotformEmbed src="https://form.jotform.com/212031402407336" />
           <Footer/>
        </>
    )
}

export default Home
