import React, {useState} from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import {Button} from '../ButtonElement'
import Video from '../videos/video.mp4'
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
    const [hover,setHover] = useState(false);

    const onHover = () => {
        setHover(!hover)
    };

    const { t } = useTranslation();

    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted src = {Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>VeraAt</HeroH1>
                    {/* <HeroP>Placeholder text</HeroP> */}
                        <HeroBtnWrapper>
                        <Button to="/portfolio" target="_blank" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark ='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                        {t('VisitHero')} {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
            
        </HeroContainer>
    )
}

export default HeroSection
