import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './i18n'

import App from './App';


const loadingMarkup = (
  <h2>Loading...</h2>
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
    <App />
    </React.StrictMode>
    </Suspense>,
  document.getElementById('root')
);


