export const images = [
    {id: 7, imgName: "other-1.jpg", tag:"other"},
    {id: 42, imgName: "other-2.jpg", tag:"other"},
    {id: 43, imgName: "other-3.jpg", tag:"other"},
    {id: 44, imgName: "other-4.jpg", tag:"other"},
    {id: 45, imgName: "other-5.jpg", tag:"other"},
    {id: 46, imgName: "other-6.jpg", tag:"other"},
    {id: 47, imgName: "other-7.jpg", tag:"other"},
    {id: 48, imgName: "other-8.jpg", tag:"other"},
    {id: 49, imgName: "other-9.jpg", tag:"other"},
    {id: 50, imgName: "other-10.jpg", tag:"other"},
    {id: 51, imgName: "other-11.jpg", tag:"other"},
    {id: 52, imgName: "other-12.jpg", tag:"other"},
    {id: 53, imgName: "other-13.jpg", tag:"other"},
    {id: 54, imgName: "other-14.jpg", tag:"other"},
    {id: 55, imgName: "other-15.jpg", tag:"other"},
    {id: 56, imgName: "other-16.jpg", tag:"other"},
    {id: 57, imgName: "other-17.jpg", tag:"other"},
    {id: 58, imgName: "other-18.jpg", tag:"other"},
    {id: 59, imgName: "other-19.jpg", tag:"other"},
    {id: 60, imgName: "other-20.jpg", tag:"other"},
    {id: 61, imgName: "other-21.jpg", tag:"other"},
    {id: 9, imgName: "references-1.jpg", tag:"references"},
    {id: 10, imgName: "references-2.jpg", tag:"references"},
    {id: 11, imgName: "references-3.jpg", tag:"references"},
    {id: 12, imgName: "references-4.jpg", tag:"references"},
    {id: 13, imgName: "references-5.jpg", tag:"references"},
    {id: 14, imgName: "references-6.jpg", tag:"references"},
    {id: 15, imgName: "references-7.jpg", tag:"references"},
    {id: 16, imgName: "references-8.jpg", tag:"references"},
    {id: 17, imgName: "references-9.jpg", tag:"references"},
    {id: 18, imgName: "references-10.jpg", tag:"references"},
    {id: 19, imgName: "references-11.jpg", tag:"references"},
    {id: 20, imgName: "references-12.jpg", tag:"references"},
    {id: 21, imgName: "references-13.jpg", tag:"references"},
    {id: 22, imgName: "references-14.jpg", tag:"references"},
    {id: 23, imgName: "references-15.jpg", tag:"references"},
    {id: 24, imgName: "references-16.jpg", tag:"references"},
    {id: 25, imgName: "references-17.jpg", tag:"references"},
    {id: 2, imgName: "archives-1.jpg", tag:"archives", alt:"Something else"},
    {id: 26, imgName: "archives-2.jpg", tag:"archives", alt:"Something else"},
    {id: 27, imgName: "archives-3.jpg", tag:"archives", alt:"Something else"},
    {id: 28, imgName: "archives-4.jpg", tag:"archives", alt:"Something else"},
    {id: 29, imgName: "archives-5.jpg", tag:"archives", alt:"Something else"},
    {id: 30, imgName: "archives-6.jpg", tag:"archives", alt:"Something else"},
    {id: 31, imgName: "archives-7.jpg", tag:"archives", alt:"Something else"},
    {id: 32, imgName: "archives-8.jpg", tag:"archives", alt:"Something else"},
    {id: 33, imgName: "archives-9.jpg", tag:"archives", alt:"Something else"},
    {id: 34, imgName: "archives-10.jpg", tag:"archives", alt:"Something else"},
    {id: 35, imgName: "archives-11.jpg", tag:"archives", alt:"Something else"},
    {id: 36, imgName: "archives-12.jpg", tag:"archives", alt:"Something else"},
    {id: 37, imgName: "archives-13.jpg", tag:"archives", alt:"Something else"},
    {id: 38, imgName: "archives-14.jpg", tag:"archives", alt:"Something else"},
    {id: 39, imgName: "archives-15.jpg", tag:"archives", alt:"Something else"},
    {id: 40, imgName: "archives-16.jpg", tag:"archives", alt:"Something else"},
    {id: 41, imgName: "archives-17.jpg", tag:"archives", alt:"Something else"},
    {id: 1, imgName: "animals-1.jpg", tag:"animals", alt:"Something"},
    {id: 62, imgName: "animals-2.jpg", tag:"animals", alt:"Something"},
    {id: 63, imgName: "animals-3.jpg", tag:"animals", alt:"Something"},
    {id: 64, imgName: "animals-4.jpg", tag:"animals", alt:"Something"},
    {id: 65, imgName: "animals-5.jpg", tag:"animals", alt:"Something"},
    {id: 66, imgName: "animals-6.jpg", tag:"animals", alt:"Something"},
    {id: 67, imgName: "animals-7.jpg", tag:"animals", alt:"Something"},
    {id: 68, imgName: "animals-8.jpg", tag:"animals", alt:"Something"},
    {id: 69, imgName: "animals-9.jpg", tag:"animals", alt:"Something"},
    {id: 70, imgName: "animals-10.jpg", tag:"animals", alt:"Something"},
    {id: 71, imgName: "animals-11.jpg", tag:"animals", alt:"Something"},
    {id: 72, imgName: "animals-12.jpg", tag:"animals", alt:"Something"},
    {id: 73, imgName: "animals-13.jpg", tag:"animals", alt:"Something"},
    {id: 74, imgName: "animals-14.jpg", tag:"animals", alt:"Something"},
    {id: 75, imgName: "animals-15.jpg", tag:"animals", alt:"Something"},
    {id: 76, imgName: "animals-16.jpg", tag:"animals", alt:"Something"},
    {id: 77, imgName: "animals-17.jpg", tag:"animals", alt:"Something"},
    {id: 78, imgName: "animals-18.jpg", tag:"animals", alt:"Something"},
    {id: 79, imgName: "animals-19.jpg", tag:"animals", alt:"Something"},
    {id: 80, imgName: "animals-20.jpg", tag:"animals", alt:"Something"},
    {id: 8, imgName: "process-1.jpg", tag:"process"},
    {id: 81, imgName: "process-2.jpg", tag:"process"},
    {id: 82, imgName: "process-3.jpg", tag:"process"},
    {id: 83, imgName: "process-4.jpg", tag:"process"},
    {id: 84, imgName: "process-5.jpg", tag:"process"},
    {id: 85, imgName: "process-6.jpg", tag:"process"},
    {id: 86, imgName: "process-7.jpg", tag:"process"},
    {id: 87, imgName: "process-8.jpg", tag:"process"},
    {id: 88, imgName: "process-9.jpg", tag:"process"},
    {id: 89, imgName: "process-10.jpg", tag:"process"},
    {id: 90, imgName: "process-11.jpg", tag:"process"},
    {id: 91, imgName: "process-12.jpg", tag:"process"},
    {id: 5, imgName: "composition-1.jpg", tag:"compositions"},
    {id: 92, imgName: "composition-2.jpg", tag:"compositions"},
    {id: 93, imgName: "composition-3.jpg", tag:"compositions"},
    {id: 94, imgName: "composition-4.jpg", tag:"compositions"},
    {id: 95, imgName: "composition-5.jpg", tag:"compositions"},
    {id: 96, imgName: "composition-6.jpg", tag:"compositions"},
    {id: 97, imgName: "composition-7.jpg", tag:"compositions"},
    {id: 98, imgName: "composition-8.jpg", tag:"compositions"},
    {id: 99, imgName: "composition-9.jpg", tag:"compositions"},
    {id: 100, imgName: "composition-10.jpg", tag:"compositions"},
    {id: 101, imgName: "composition-11.jpg", tag:"compositions"},
    {id: 102, imgName: "composition-12.jpg", tag:"compositions"},
    {id: 103, imgName: "composition-13.jpg", tag:"compositions"},
    {id: 104, imgName: "composition-14.jpg", tag:"compositions"},
    {id: 105, imgName: "composition-15.jpg", tag:"compositions"},
    {id: 106, imgName: "composition-16.jpg", tag:"compositions"},
    {id: 107, imgName: "composition-17.jpg", tag:"compositions"},
    {id: 108, imgName: "composition-18.jpg", tag:"compositions"},
    {id: 109, imgName: "composition-19.jpg", tag:"compositions"},
    {id: 3, imgName: "black-and-white-1.jpg", tag:"B&W"},
    {id: 110, imgName: "black-and-white-2.jpg", tag:"B&W"},
    {id: 111, imgName: "black-and-white-3.jpg", tag:"B&W"},
    {id: 112, imgName: "black-and-white-4.jpg", tag:"B&W"},
    {id: 113, imgName: "black-and-white-5.jpg", tag:"B&W"},
    {id: 114, imgName: "black-and-white-6.jpg", tag:"B&W"},
    {id: 115, imgName: "black-and-white-7.jpg", tag:"B&W"},
    {id: 116, imgName: "black-and-white-8.jpg", tag:"B&W"},
    {id: 117, imgName: "black-and-white-9.jpg", tag:"B&W"},
    {id: 118, imgName: "black-and-white-10.jpg", tag:"B&W"},
    {id: 119, imgName: "black-and-white-11.jpg", tag:"B&W"},
    {id: 120, imgName: "black-and-white-12.jpg", tag:"B&W"},
    {id: 121, imgName: "black-and-white-13.jpg", tag:"B&W"},
    {id: 122, imgName: "black-and-white-14.jpg", tag:"B&W"},
    {id: 123, imgName: "black-and-white-15.jpg", tag:"B&W"},
    {id: 124, imgName: "black-and-white-16.jpg", tag:"B&W"},
    {id: 125, imgName: "black-and-white-17.jpg", tag:"B&W"},
    {id: 126, imgName: "black-and-white-18.jpg", tag:"B&W"},
    {id: 6, imgName: "oil-order-1.jpg", tag:"oil orders"},
    {id: 127, imgName: "oil-order-2.jpg", tag:"oil orders"},
    {id: 128, imgName: "oil-order-3.jpg", tag:"oil orders"},
    {id: 129, imgName: "oil-order-4.jpg", tag:"oil orders"},
    {id: 130, imgName: "oil-order-5.jpg", tag:"oil orders"},
    {id: 131, imgName: "oil-order-6.jpg", tag:"oil orders"},
    {id: 132, imgName: "oil-order-7.jpg", tag:"oil orders"},
    {id: 133, imgName: "oil-order-8.jpg", tag:"oil orders"},
    {id: 134, imgName: "oil-order-9.jpg", tag:"oil orders"},
    {id: 135, imgName: "oil-order-10.jpg", tag:"oil orders"},
    {id: 136, imgName: "oil-order-11.jpg", tag:"oil orders"},
    {id: 137, imgName: "oil-order-12.jpg", tag:"oil orders"},
    {id: 138, imgName: "oil-order-13.jpg", tag:"oil orders"},
    {id: 139, imgName: "oil-order-14.jpg", tag:"oil orders"},
    {id: 140, imgName: "oil-order-15.jpg", tag:"oil orders"},
    {id: 141, imgName: "oil-order-16.jpg", tag:"oil orders"},
    {id: 142, imgName: "oil-order-17.jpg", tag:"oil orders"},
    {id: 143, imgName: "oil-order-18.jpg", tag:"oil orders"},
    {id: 144, imgName: "oil-order-19.jpg", tag:"oil orders"},
    {id: 145, imgName: "oil-order-20.jpg", tag:"oil orders"},
    {id: 146, imgName: "oil-order-21.jpg", tag:"oil orders"},
    {id: 147, imgName: "oil-order-22.jpg", tag:"oil orders"},
    {id: 148, imgName: "oil-order-23.jpg", tag:"oil orders"},
    {id: 4, imgName: "colour-portrait-1.jpg", tag:"colour portraits"},
    {id: 149, imgName: "colour-portrait-2.jpg", tag:"colour portraits"},
    {id: 150, imgName: "colour-portrait-3.jpg", tag:"colour portraits"},
    {id: 151, imgName: "colour-portrait-4.jpg", tag:"colour portraits"},
    {id: 152, imgName: "colour-portrait-5.jpg", tag:"colour portraits"},
    {id: 153, imgName: "colour-portrait-6.jpg", tag:"colour portraits"},
    {id: 154, imgName: "colour-portrait-7.jpg", tag:"colour portraits"},
    {id: 155, imgName: "colour-portrait-8.jpg", tag:"colour portraits"},
    {id: 156, imgName: "colour-portrait-9.jpg", tag:"colour portraits"},
    {id: 157, imgName: "colour-portrait-10.jpg", tag:"colour portraits"},
    {id: 158, imgName: "colour-portrait-11.jpg", tag:"colour portraits"},
    {id: 159, imgName: "colour-portrait-12.jpg", tag:"colour portraits"},
    {id: 160, imgName: "colour-portrait-13.jpg", tag:"colour portraits"},
    {id: 161, imgName: "colour-portrait-14.jpg", tag:"colour portraits"},
    {id: 162, imgName: "colour-portrait-15.jpg", tag:"colour portraits"},
    {id: 163, imgName: "colour-portrait-16.jpg", tag:"colour portraits"},
    {id: 164, imgName: "colour-portrait-17.jpg", tag:"colour portraits"},
    {id: 165, imgName: "colour-portrait-18.jpg", tag:"colour portraits"},
    {id: 166, imgName: "colour-portrait-19.jpg", tag:"colour portraits"},
    {id: 167, imgName: "colour-portrait-20.jpg", tag:"colour portraits"},
    {id: 168, imgName: "colour-portrait-21.jpg", tag:"colour portraits"},
    {id: 169, imgName: "colour-portrait-22.jpg", tag:"colour portraits"},
    {id: 170, imgName: "colour-portrait-23.jpg", tag:"colour portraits"},
    {id: 171, imgName: "colour-portrait-24.jpg", tag:"colour portraits"},
    {id: 172, imgName: "colour-portrait-25.jpg", tag:"colour portraits"},
    {id: 173, imgName: "colour-portrait-26.jpg", tag:"colour portraits"},
]
