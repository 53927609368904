import React from 'react'
import { Button } from '../ButtonElement'
import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ButtonWrap, ImgWrap, Img } from './AboutElements'
import { useTranslation } from 'react-i18next'



const AboutProcess = () => {
    const {t} = useTranslation();
    return (
        <>
          <AboutContainer lightBg={true} id = 'process'>
              <AboutWrapper>
                  <AboutRow imgStart = {false}>
                      <Column1>
                      <TextWrapper>
                          <TopLine>{''}</TopLine>
                            <Heading lightText={false}>{t("Process")}</Heading> 
                            <Subtitle darkText={true}>{t("processDesc")}</Subtitle>
                           
                      </TextWrapper>
                      </Column1>
                      <Column2>
                      <ImgWrap>
                      <Img src={require('../img/process.svg').default}/>
                      </ImgWrap>
                      </Column2>
                  </AboutRow>
              </AboutWrapper>
              </AboutContainer>  
        </>
    )
}

export default AboutProcess
